export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/features/account/views/Login.vue"),
    },
    {
        path: "/change-password",
        name: "changePassword",
        component: () => import("@/features/account/views/ChangePassword.vue"),
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/features/account/views/ResetPassword.vue"),
    },
    {
        path: "/request-reset-password",
        name: "requestResetPassword",
        component: () => import("@/features/account/views/RequestResetPassword.vue"),
    },
    {
        path: "/setup",
        name: "setup",
        component: () => import("@/features/account/views/Setup.vue"),
    }
];
